import cx from "classnames";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import TemplateCarousel from "./TemplateCarousel";
import * as styles from "./VideoSection.module.scss";

const VideoSection = props => {
  const iphoneMock = useStaticQuery(graphql`
    query {
      iphoneImage: file(
        relativePath: { eq: "video-ads-page/iphone-mock@3x.png" }
      ) {
        childImageSharp {
          fixed(width: 335) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  return (
    <section className={cx(styles.videoSection)} id="video-section">
      <div className={styles.sbvPreviewContainer}>
        <Img fixed={iphoneMock.iphoneImage.childImageSharp.fixed} />
        {/* <TemplateCarousel /> */}
      </div>
    </section>
  );
};

export default VideoSection;
