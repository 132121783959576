import React from "react";
import { animated, useSpring } from "react-spring";
import ColorSelector from "../../../assets/video-ads-page/color-selector.svg";
import Frostier from "../../../assets/video-ads-page/FROSTIER_small.mp4";
import Letters from "../../../assets/video-ads-page/letters.svg";
import Products from "../../../assets/video-ads-page/products.svg";
import SoundtrackSelector from "../../../assets/video-ads-page/soundtrack-selector.svg";
import Star from "../../../assets/video-ads-page/star.svg";
import FrostierThumbnail from "../../../images/video-thumbnails/tn-frostier.png";
import FeatureItem from "../FeatureItem";
import FeatureSplitContainer from "../FeatureSplitContainer";
import { navigateToSignupFromSBv } from "../utils";
import * as styles from "./CreateSection.module.scss";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const musicTranslate = (x, y) => `translate3d(${x / 40}px,${y / 30}px,0)`;
const colorTranslate = (x, y) => `translate3d(${x / 80}px,${y / 30}px,0)`;

const CreateSection = props => {
  const {t} = useTranslation();
  const [springProps, setSpringProps] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 300, friction: 140 }
  }));

  const CreateFeatures = [
    {
      text: t("Custom Product Title"),
      icon: Letters
    },
    {
      text: t("Any Product/ASIN"),
      icon: Products
    },
    {
      text: t("Key Product Features"),
      icon: Star
    }
  ];

  const creativeParallax = (
    <div className={styles.creative}>
      <div className={styles.videoPlayerContainer}>
        <video
          id={"frostier-video-player"}
          muted
          controls={false}
          loop
          preload="metadata"
          playsInline
          autoPlay
          crossOrigin="anonymous"
          poster={FrostierThumbnail}
        >
          <source src={Frostier} type="video/mp4" />
          <img src={FrostierThumbnail} alt="frostier-template" />
        </video>
      </div>

      <animated.div
        style={{ transform: springProps.xy.interpolate(musicTranslate) }}
        className={styles.soundtrackSelector}
      >
        <SoundtrackSelector />
      </animated.div>

      <animated.div
        style={{
          transform: springProps.xy.interpolate(colorTranslate)
        }}
        className={styles.colorSelector}
      >
        <ColorSelector />
      </animated.div>
    </div>
  );

  const createFeaturesList = (
    <div className={styles.createFeatureContainer}>
      {CreateFeatures.map(f => FeatureItem(f))}
    </div>
  );

  return (
    <section
      className={styles.createSection}
      onMouseMove={({ clientX: x, clientY: y }) =>
        setSpringProps({ xy: calc(x, y) })
      }
    >
      <FeatureSplitContainer
        buttonId="features-video-create"
        title={t("Create")}
        body={
          t("Perpetua's video creator lets you create your own thumb-stopping videos in seconds for any product, without expensive production costs. Choose your product, your theme, customize for your brand and you're ready to launch.")
        }
        creative={creativeParallax}
        reversed
        customBody={createFeaturesList}
        ctaText={t("Create Your Video")}
        ctaOnClick={navigateToSignupFromSBv}
      />
    </section>
  );
};

export default CreateSection;
