import classNames from "classnames/bind";
import * as React from "react";
import { navigateToSignupFromSBv } from "../utils";
import CtaCard, { CtaCardType } from "./CtaCard";
import * as styles from "./PricingAndCtaSection.module.scss";
import { CTA } from "../../../constants/text";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const cx = classNames.bind(styles);

const PricingAndCtaSection = props => {
  const {t} = useTranslation();
  return (
    <section className={cx(styles.pricingAndCtaSection, "container")}>
      <CtaCard
        buttonId="features-video-bottom-getstarted"
        type={CtaCardType.primary}
        title={t("Get Started Now")}
        description={t("Get started with your first video free.")}
        buttonCta={t("Get Started")}
        onClick={()=> {
          navigateToSignupFromSBv();
        }}
      />
      <CtaCard
        buttonId="features-video-bottom-theme"
        type={CtaCardType.secondary}
        title={t("Custom Themes")}
        description={
          t("Let our creative team build a custom theme unique to your brand in 10 days.")
        }
        buttonCta={t(CTA.Theme)}
      />
    </section>
  );
};

export default PricingAndCtaSection;
