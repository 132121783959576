import className from "classnames/bind";
import PropTypes from "prop-types";
import React from "react";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import * as styles from "./FeatureSplitContainer.module.scss";

const cx = className.bind(styles);

interface Props {
  buttonId?: string;
  title?: string;
  creative?: string;
  body?: string;
  instructions?: string;
  reversed?: boolean;
  customBody?: string;
  ctaText?: string;
  ctaOnClick?: ()=> null;
}

const FeatureSplitContainer: React.FC<Props> = props => {
  const {
    buttonId,
    title,
    body,
    creative,
    reversed,
    customBody,
    ctaText,
    ctaOnClick
  } = props;
  const showButton = ctaOnClick && ctaText;

  return (
    <div
      className={cx(styles.featureSplitContainer, "container", {
        reversed
      })}
    >
      <div className={styles.textContent}>
        <h2>{title}</h2>
        <p>{body}</p>
        {customBody && customBody}
        {showButton && (
          <Button
            id={buttonId}
            text={ctaText}
            onClick={()=> {
              ctaOnClick();
            }}
            variant={ButtonVariant.primary}
            size={ButtonSize.large}
            className={styles.ctaButton}
          />
        )}
      </div>
      {creative}
    </div>
  );
};

export default FeatureSplitContainer;

FeatureSplitContainer.propTypes = {
  buttonId: PropTypes.string,
  reversed: PropTypes.bool,
  title: PropTypes.string.isRequired,
  body: PropTypes.string.isRequired,
  creative: PropTypes.element.isRequired,
  benefits: PropTypes.arrayOf(PropTypes.element)
};
