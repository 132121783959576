import React from "react";
import { animated, useSpring } from "react-spring";
import GoalList from "../../../assets/video-ads-page/goal-list.svg";
import GoalRow from "../../../assets/video-ads-page/goal-row.svg";
import Lightbulb from "../../../assets/video-ads-page/lightbulb.svg";
import Optimize from "../../../assets/video-ads-page/optimize.svg";
import Percent from "../../../assets/video-ads-page/percent.svg";
import FeatureItem from "../FeatureItem";
import FeatureSplitContainer from "../FeatureSplitContainer";
import { navigateToSignupFromSBv } from "../utils";
import * as styles from "./OptimizeSection.module.scss";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const goalRowTranslate = (x, y) => `translate3d(${x / 50}px,${y / 50}px,0)`;

const OptimizeSection = props => {
  const {t} = useTranslation();
  const OptimizeFeatures = [
    {
      icon: Optimize,
      text: t("Always-on Optimizations")
    },
    {
      icon: Percent,
      text: t("Creative Performance Metrics")
    },
    {
      icon: Lightbulb,
      text: t("Intelligence Reporting Insights")
    }
  ];
  
  const [springProps, setSpringProps] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const creative = (
    <div className={styles.creative}>
      <GoalList />
      <animated.div
        className={styles.goalRow}
        style={{ transform: springProps.xy.interpolate(goalRowTranslate) }}
      >
        <GoalRow />
      </animated.div>
    </div>
  );

  const featuresList = (
    <div className={styles.optimizeFeaturesContainer}>
      {OptimizeFeatures.map(f => FeatureItem(f))}
    </div>
  );

  return (
    <section
      className={styles.optimizeSection}
      onMouseMove={({ clientX: x, clientY: y }) =>
        setSpringProps({ xy: calc(x, y) })
      }
    >
      <FeatureSplitContainer
        buttonId="features-video-optimize"
        title={t("Optimize")}
        body={
          t("The only Sponsored Brands video campaigns powered by AI. Harness Perpetua's powerful ad engine to meet shoppers at the right time across the entire customer journey. Drive new-to-brand customers and maximize the efficiency of your advertising budget.")
        }
        creative={creative}
        reversed
        customBody={featuresList}
        ctaText={t("Optimize Your Video")}
        ctaOnClick={navigateToSignupFromSBv}
      />
    </section>
  );
};

export default OptimizeSection;
