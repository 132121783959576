import cx from "classnames";
import React from "react";
import { animated, useSpring } from "react-spring";
import ColorIcon from "../../../assets/video-floating-icons/color.svg";
import EditIcon from "../../../assets/video-floating-icons/edit.svg";
import ImageIcon from "../../../assets/video-floating-icons/image.svg";
import NameIcon from "../../../assets/video-floating-icons/name.svg";
import TemplateIcon from "../../../assets/video-floating-icons/template.svg";
import VideoIcon from "../../../assets/video-floating-icons/video.svg";
import Button, { ButtonSize, ButtonVariant } from "../../Button";
import { navigateToSignupFromSBv } from "../utils";
import FloatingIcon from "./FloatingIcon";
import * as styles from "./HeroSection.module.scss";
import { CTA } from "../../../constants/text";
import { URL } from "../../../constants/destinations";
import { Name, Type, Location } from "../../../utils/analytics/constants";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const iconTranslateSmall = (x, y) => `translate3d(${x / 50}px,${y / 30}px,0)`;
const iconTranslateMedium = (x, y) => `translate3d(${x / 18}px,${y / 25}px,0)`;
const iconTranslateBig = (x, y) => `translate3d(${x / 10}px,${y / 10}px,0)`;

const HeroSection: React.FC<{}> = props => {
  const {t} = useTranslation();

  const [springProps, setSpringProps] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 300, friction: 140 }
  }));


  return <section
      className={cx(styles.heroSection, "container")}
      onMouseMove={({ clientX: x, clientY: y }) =>
        setSpringProps({ xy: calc(x, y) })
      }
    >
      <h1 className="m-auto">
        {t("Create Amazon video ads in seconds.")}
      </h1>
      <h4 className="m-0">{t("Sponsored Brands video ads, powered by Perpetua.")}</h4>
      <h4>{t("Get started with your first video free.")}</h4>
      <div className={styles.buttonBar}>
        <Button
          id="features-video-getstarted"
          className={styles.ctaButton}
          variant={ButtonVariant.primary}
          size={ButtonSize.large}
          text={t(CTA.GetStarted)}
          onClick={()=> {
            navigateToSignupFromSBv();
          }}
        />
      </div>

      <animated.div
        style={{
          transform: springProps.xy.interpolate(iconTranslateMedium)
        }}
      >
        <FloatingIcon icon={<TemplateIcon />} top={-96} left={98} />
      </animated.div>

      <animated.div
        style={{
          transform: springProps.xy.interpolate(iconTranslateBig)
        }}
      >
        <FloatingIcon icon={<ColorIcon />} top={-318} left={0} />
      </animated.div>

      <animated.div
        style={{
          transform: springProps.xy.interpolate(iconTranslateSmall)
        }}
      >
        <FloatingIcon icon={<VideoIcon />} top={-484} left={244} />
      </animated.div>

      <animated.div
        style={{
          transform: springProps.xy.interpolate(iconTranslateSmall)
        }}
      >
        <FloatingIcon icon={<EditIcon />} top={-56} right={120} />
      </animated.div>

      <animated.div
        style={{
          transform: springProps.xy.interpolate(iconTranslateMedium)
        }}
      >
        <FloatingIcon icon={<NameIcon />} top={-258} right={0} />
      </animated.div>

      <animated.div
        style={{
          transform: springProps.xy.interpolate(iconTranslateBig)
        }}
      >
        <FloatingIcon icon={<ImageIcon />} top={-444} right={194} />
      </animated.div>
    </section>
};

export default HeroSection;
