import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { useTranslation } from "react-i18next";
import * as styles from "./BrandSection.module.scss";

const BrandSection = props => {
  const {t} = useTranslation();
  const images = useStaticQuery(graphql`
    query {
      #  Sharper Image Query
      sharperImage: file(
        relativePath: { eq: "video-ad-customers/logo-sharper-img@3x.png" }
      ) {
        childImageSharp {
          fixed(width: 232) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      #  DR.T Query
      drT: file(relativePath: { eq: "video-ad-customers/dr-t@3x.png" }) {
        childImageSharp {
          fixed(width: 84) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      # Four Sigmatic Query
      fourSigmatic: file(
        relativePath: { eq: "video-ad-customers/fs-logo@3x.png" }
      ) {
        childImageSharp {
          fixed(width: 130) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      # Bee Keepers Query
      beeKeepers: file(
        relativePath: { eq: "video-ad-customers/beekeepers@3x.png" }
      ) {
        childImageSharp {
          fixed(width: 52) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }

      #  HVMN Query
      hvmn: file(relativePath: { eq: "video-ad-customers/hvmn-logo@3x.png" }) {
        childImageSharp {
          fixed(width: 169) {
            ...GatsbyImageSharpFixed_withWebp_tracedSVG
          }
        }
      }
    }
  `);

  const links = {
    hvmn: "https://hvmn.com/"
  };

  const onClick = url => {
    window.open(url, "noopener");
  };

  return (
    <section className={styles.brandSection}>
      <div className="container">
        <h5>{t("Powering Video For Top Brands On Amazon")}</h5>
        <div className={styles.customerBar}>
          {Object.keys(images).map((key, idx) => {
            const image = images[key];
            const link = links[key];

            return (
              <div
                className={link ? styles.clickable : undefined}
                onClick={link ? () => onClick(link) : undefined}
              >
                <Img
                  key={idx}
                  fixed={image.childImageSharp.fixed}
                  alt={`customer ${idx}`}
                />
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default BrandSection;
