import React from "react";
import { graphql } from "gatsby";
import NavigationBarSpacer from "../components/NavigationBarSpacer";
import SEO from "../components/Seo";
import BrandSection from "../components/VideoAdsPage/BrandSection";
import CreateSection from "../components/VideoAdsPage/CreateSection";
import HeroSection from "../components/VideoAdsPage/HeroSection";
import LaunchSection from "../components/VideoAdsPage/LaunchSection";
import OptimizeSection from "../components/VideoAdsPage/OptimizeSection";
import PricingAndCtaSection from "../components/VideoAdsPage/PricingAndCtaSection";
import "../components/VideoAdsPage/video-ads-page.scss";
import VideoSection from "../components/VideoAdsPage/VideoSection";
import Layout from "../containers/Layout";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const VideoPage: React.FC<{}> = props => {
  const {t} = useTranslation();
  return (
    <Layout primaryButtonId="features-video-nav-getstarted">
      <SEO
        title={t("Sponsored Brands video")}
        description={
          t("Create and launch Amazon Sponsored Brands video ads in seconds with Perpetua's video creator tool. Maximize performance with Perpetua's AI-powered ad engine and always-on automated optimizations.")
        }
      />
      <div className="sponsored-brands-video">
        <NavigationBarSpacer />
        <NavigationBarSpacer />
        <HeroSection />
        <VideoSection />
        <BrandSection />
        <CreateSection />
        <LaunchSection />
        <OptimizeSection />
        <PricingAndCtaSection />
      </div>
    </Layout>
  );
};

export default VideoPage;

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;