import React from "react";
import * as styles from "./FeatureItem.module.scss";

const FeatureItem = props => {
  const Icon = props.icon;
  return (
    <div className={styles.featureItem}>
      <div className={styles.featureItemIconCircle}>
        <Icon />
      </div>
      <div className={styles.featureItemText}>{props.text}</div>
    </div>
  );
};

export default FeatureItem;
