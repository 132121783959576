import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import React from "react";
import { animated, useSpring } from "react-spring";
import Rank from "../../../assets/video-ads-page/best-seller-rank.svg";
import Dollar from "../../../assets/video-ads-page/edit-bid.svg";
import Frostier from "../../../assets/video-ads-page/FROSTIER_small.mp4";
import Chart from "../../../assets/video-ads-page/sales.svg";
import FrostierThumbnail from "../../../images/video-thumbnails/tn-frostier.png";
import FeatureItem from "../FeatureItem";
import FeatureSplitContainer from "../FeatureSplitContainer";
import { navigateToSignupFromSBv } from "../utils";
import * as styles from "./LaunchSection.module.scss";
import { useTranslation } from 'gatsby-plugin-react-i18next';

const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
const videoTranslate = (x, y) => `translate3d(${x / 50}px,${y / 50}px,0)`;



const LaunchSection = props => {
  const {t} = useTranslation();
  
  // Animations
  const [springProps, setSpringProps] = useSpring(() => ({
    xy: [0, 0],
    config: { mass: 10, tension: 550, friction: 140 }
  }));

  const LaunchFeatures = [
    {
      text: t("Cost Savings"),
      icon: Dollar
    },
    {
      text: t("Competitive Advantage"),
      icon: Rank
    },
    {
      text: t("Increased Sales"),
      icon: Chart
    }
  ];

  const image = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "video-ads-page/amazon-page@3x.png" }) {
        childImageSharp {
          fluid(maxWidth: 548) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `);
  const creative = (
    <div className={styles.creative}>
      <div className={styles.amazonPage}>
        <Img fluid={image.file.childImageSharp.fluid} alt={"amazon page"} />
      </div>
      <animated.div
        className={styles.videoContainer}
        style={{ transform: springProps.xy.interpolate(videoTranslate) }}
      >
        <video
          id={"frostier-video-player-2"}
          muted
          controls={false}
          loop
          preload="metadata"
          playsInline
          autoPlay
          crossOrigin="anonymous"
          poster={FrostierThumbnail}
        >
          <source src={Frostier} type="video/mp4" />
          <img src={FrostierThumbnail} alt="frostier-template" />
        </video>
      </animated.div>
    </div>
  );

  const launchFeaturesList = (
    <div className={styles.launchFeatureContainer}>
      {LaunchFeatures.map(f => FeatureItem(f))}
    </div>
  );

  return (
    <section
      className={styles.launchSection}
      onMouseMove={({ clientX: x, clientY: y }) =>
        setSpringProps({ xy: calc(x, y) })
      }
    >
      <FeatureSplitContainer
        buttonId="features-video-launch"
        title={t("Launch")}
        body={
          t("Help shoppers find your brand and products by matching search intent with captivating, motion video ads at scale. Hot start your Sponsored Brands video campaigns with performance data from Sponsored Products campaigns.")
        }
        creative={creative}
        customBody={launchFeaturesList}
        ctaText={t("Launch Your Video")}
        ctaOnClick={navigateToSignupFromSBv}
      />
    </section>
  );
};

export default LaunchSection;
